import { ContractCondition } from '../data/contract-conditions';

export const getFormattedListingCategories = (condition: ContractCondition) => {
  const {
    allow_commercial_rental: commRental,
    allow_commercial_sale: commSale,
    allow_business_sale: busiSale,
    allow_commercial_sale_rental: commSaleRental,
    allow_land_sale: landSale,
    allow_residential_sale: resiSale,
    allow_rural_sale: ruralSale
  } = condition;

  const appliedCategories: string[] = [];

  if (commRental) appliedCategories.push('Commercial Rental');
  if (commSale) appliedCategories.push('Commercial Sale');
  if (busiSale) appliedCategories.push('Business Sale');
  if (commSaleRental) appliedCategories.push('Commercial Sale or Rental');
  if (landSale) appliedCategories.push('Land Sale');
  if (resiSale) appliedCategories.push('Residential Sale');
  if (ruralSale) appliedCategories.push('Rural Sale');

  if (appliedCategories.length === 7) return 'All';
  return appliedCategories.join(', ');
};
