import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Body } from 'components/text/body';
import Icon, { ICONS } from 'shared/components/icon';
import Spinner from 'shared/components/spinner';
import { COLORS } from 'shared/theme';

const styles = StyleSheet({
  dropZoneBox: {
    position: 'absolute',
    top: 4,
    left: 0,
    width: 'calc(100% - 25px)',
    height: 'calc(100% - 4px)',
    backgroundColor: COLORS.NEW_REX_BRAND.CALENDAR_BLUE_10,
    border: `1px dashed ${COLORS.NEW_REX_BRAND.CALENDAR_BLUE}`,
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 1
  },
  dragging: {
    opacity: 1
  },
  uploading: {
    top: 28,
    height: 'calc(100% - 28px)',
    backgroundColor: COLORS.SAND_LIGHT,
    border: 'none'
  }
});

interface DropZoneBoxProps {
  isUploading: boolean;
  progressText: string;
  isDragging: boolean;
}

const DropZoneBox = ({
  isDragging,
  isUploading,
  progressText
}: DropZoneBoxProps) => {
  const s = useStyles(styles);

  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      {...s('dropZoneBox', { dragging: isDragging, uploading: isUploading })}
    >
      <Box flexDirection='column' alignItems='center'>
        {isUploading ? (
          <>
            <Spinner classic dark small style={{ marginBottom: 12 }} />
            <Body small semibold normal>
              {progressText}
            </Body>
          </>
        ) : (
          <>
            <Icon
              type={ICONS.UPLOAD_NEW}
              width={24}
              height={24}
              style={{
                color: COLORS.DASHBOARDS.DARK
              }}
              hasControlledColor={false}
            />
            <Body small semibold normal dark>
              Drag & drop file
            </Body>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DropZoneBox;
